import React, { useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { AuthContext } from 'src/contexts/authContext';

import {
    CodeInput,
    InputLabel,
    PhoneInput,
    SelectInput,
} from 'src/components/common/form/input';

import { getCountryCodeNumber } from 'src/libs/utils';
import PrimaryButton from 'src/components/common/buttons/PrimaryButton';
import { Controller, useForm } from 'react-hook-form';
import { authValidationRules, validatePhone } from 'src/libs/validation';
import { CountryCode } from 'libphonenumber-js';
import { countryCodes } from 'src/libs/constants';
import { logInSendCodeByPhone } from 'src/libs/cognito';
import { COUNTDOWN_VALUE } from 'src/constants';
import Countdown from 'src/components/general/Countdown';
import useServerErrorHandler from 'src/hooks/useServerErrorHandler';
import { endLoading, startLoading } from 'src/store/slices/loadingSlice';
import clsx from 'clsx';

type TDefaultValue = {
    countryCode: CountryCode;
    authPhone: string;
    phoneCode: string;
};

const defaultValues: TDefaultValue = {
    countryCode: countryCodes[0].value,
    authPhone: '',
    phoneCode: '',
};

const LoginByPhone: React.FunctionComponent<{}> = () => {
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        control,
        watch,
        formState: { errors, isValid },
    } = useForm({
        mode: 'all',
        defaultValues,
    });

    const { countryCode, authPhone, phoneCode } = watch();

    const [showCodeInput, setShowCodeInput] = useState(false);
    const [blockEditPhone, setBlockEditPhone] = useState(false);
    const [countDown, setCountDown] = useState(0);
    const [handleServerError] = useServerErrorHandler();
    const { verifyCodeSentToPhone, signUpSendCodeByPhone, resendCodeToPhone } =
        useContext(AuthContext);

    const onSendACode = ({ countryCode, authPhone, phoneCode }: any) => {
        dispatch(startLoading());
        setBlockEditPhone(true);
        logInSendCodeByPhone(getCountryCodeNumber(countryCode) + authPhone)
            .then(() => {
                setBlockEditPhone(false);
                setCountDown(COUNTDOWN_VALUE);
                setShowCodeInput(true);
            })
            .catch((err: any) => {
                setBlockEditPhone(false);
                handleServerError(err);
            })
            .finally(() => dispatch(endLoading()));
    };

    const onLoginByPhoneClick = ({
        countryCode,
        authPhone,
        phoneCode,
    }: any) => {
        dispatch(startLoading());
        return verifyCodeSentToPhone(
            getCountryCodeNumber(countryCode) + authPhone,
            phoneCode,
        )
            .then(() => {})
            .catch((err: any) => handleServerError(err))
            .finally(() => dispatch(endLoading()));
    };

    const onResendCode = () => {
        resendCodeToPhone(getCountryCodeNumber(countryCode) + authPhone)
            .then((res) => {
                setCountDown(COUNTDOWN_VALUE);
            })
            .catch((err) => handleServerError(err));
    };

    const errorPhone = validatePhone(countryCode, authPhone);

    return (
        <form
            onSubmit={handleSubmit(
                showCodeInput ? onLoginByPhoneClick : onSendACode,
            )}
        >
            <div
                className={clsx(
                    `flex flex-col xl:flex-row relative inline-block text-left`,
                    { 'mb-[1.865rem]': !showCodeInput },
                )}
            >
                <div className="flex-1 mt-7 min-w-[7.5rem] xl:mr-7 relative">
                    <Controller
                        name="countryCode"
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <SelectInput
                                label="Country Code"
                                {...register('countryCode')}
                                onBlur={onBlur}
                                onChange={onChange}
                                value={value}
                                disabled={blockEditPhone || showCodeInput}
                                options={countryCodes}
                            />
                        )}
                    />
                </div>
                <div className="mt-7 w-full">
                    <InputLabel htmlFor="password">Phone Number</InputLabel>
                    <div className="mt-1">
                        <PhoneInput
                            disabled={blockEditPhone || showCodeInput}
                            placeholder="Enter"
                            registerReactHookInput={register('authPhone', {
                                validate:
                                    authValidationRules.authPhone.validate,
                            })}
                            error={errorPhone || errors.authPhone}
                        />
                    </div>
                </div>
            </div>
            {showCodeInput && (
                <>
                    <div className="mt-7.5">
                        <Controller
                            name="phoneCode"
                            control={control}
                            render={({
                                field: { onChange, onBlur, value },
                            }) => (
                                <CodeInput
                                    name="phoneCode"
                                    inputMode="numeric"
                                    // onBlur={onBlur}
                                    onChange={onChange}
                                    value={value}
                                />
                            )}
                        />
                    </div>
                    {!!countDown ? (
                        <div className="my-7.5">
                            SMS was sent to your phone. If you didn&apos;t
                            receive it - use the button to resent &nbsp;
                            <Countdown
                                countDown={countDown}
                                setCountDown={setCountDown}
                            />
                        </div>
                    ) : (
                        <a
                            className="font-mont font-bold flex text-blue my-7.5"
                            onClick={(e) => {
                                e.preventDefault();
                                onResendCode();
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            Send a code via SMS again?
                        </a>
                    )}
                </>
            )}

            {showCodeInput ? (
                <PrimaryButton
                    disabled={
                        !!errorPhone || !isValid || phoneCode.length !== 6
                    }
                    label="Sign In"
                />
            ) : (
                <PrimaryButton
                    type="submit"
                    disabled={!!errorPhone || !isValid}
                    label="Send a code"
                />
            )}
        </form>
    );
};

export default LoginByPhone;
